:root {

    --primary-color: #a62173;
    --background-color: #252025;
    --shadow-color: #240312e6;
    --shadow: 0 15px 30px var(--shadow-color);
    --shadow-grey: 0 15px 30px #160e12e6;
    --radial-gradient: radial-gradient(circle, rgba(167,33,115,1) 0%, rgba(176,41,124,1) 79%, rgba(224,110,190,1) 100%);
    --white: #fcd5ed;
    --navigation-bar-color: #18030c1a;
    --glow: 0 0 10px var(--primary-color);
    --glow-text: 0 0 20px var(--primary-color);
    --glow-white: 0 0 12px var(--white);
    --glow-white-text: 0 0 20px var(--white);
    
    background-color: var(--background-color);
    
}