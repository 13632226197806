.contact-form{

    position: relative;
    display: block;
    width: 60%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 14pt;

    &__form {
        width: 100%;
        border-radius: 20px;
        padding-right: 20px;

        label {
            display: block;
            margin-bottom: 2px;
            p{
                color: var(--white);
            }
        }

        input{
            background-color: var(--background-color);
            padding: 10px;
        }

        input[type="text"],
        input[type="email"],
        textarea {
            color: var(--white);
            width: 100%;
            border: 4px solid var(--primary-color);
            background-color: var(--background-color);
            border-radius: 25px;
        }

        input[type="message"],
        textarea {
            color: var(--white);
            height: 100px;
            border-radius: 20px;
            background-color: var(--background-color);
            padding: 10px;
        }

        input[type="submit"] {
            width: 100%;
            cursor: pointer;
            border-width: 4px;
            background-color: var(--background-color);
        }

        button {
            width: 100%;
            padding: 10px;
            background-color: rgba(105, 25, 83, 0);
            border: none;
            border-radius: 50px;
            position: relative;
            overflow: hidden;

            span {
                font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                font-weight: 400;
                font-size: 14pt;
                color: var(--white);
                position: relative;
                z-index: 1;
            }


            ::after {
                content: '';
                position: absolute;
                top: 25px;
                left: 0;
                bottom: 0;
                width: 0;
                height: 3px;
                background-color: var(--white);
                transition: width 0.3s ease;
            }

            &.navbar__menu__link__active::after,
            :hover::after {
                width: 100%;
            }
        }
    } 
}

.message-sent{
    text-align: center;
    width: 100%;
    
    h1, p{
        text-align: center;
    }
}