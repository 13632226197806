.navbar {
    position: absolute;
    top: 30px;
    left: 30%;
    right: 30%;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    z-index: 10;
    //border: 2px solid red;

    &__menu {
        box-shadow: var(--shadow-grey);
        //border: 2px solid orange;
        border-width: 0px 3px 0px 3px;
        //border-width: 3px;
        border-color: var(--primary-color);
        border-style: solid;
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1;
        backdrop-filter: blur(10px);
        border-radius: 100px;
        //background-color: var(--navigation-bar-color);
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: center;
        //box-shadow: var(--glow);
        //box-shadow: 2px 0px 1px var(--primary-color);
        display: flex;
        list-style: none;
        text-align: center;
        gap: 90px;

        &__link {

            button {
                padding: 10px;
                background-color: rgba(105, 25, 83, 0);
                border: none;
                border-radius: 50px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                span {
                    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-weight: 400;
                    font-size: 18pt;
                    color: var(--white);
                    position: relative;
                    z-index: 1;
                }


                ::after {
                    content: '';
                    position: absolute;
                    top: 25px;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 3px;
                    background-color: var(--white);
                    transition: width 0.3s ease;
                }

                &.navbar__menu__link__active::after,
                :hover::after {
                    width: 100%;
                }
            }

            .active{

                //background-color: rgba(27, 0, 19, 0.3);

                span {
                    font-weight: 600;
                    color: var(--primary-color);
                }

                ::after {
                    width: 100%;
                    background-color: var(--primary-color);
                }
            }
        }
    }
}

.nav-icon {
    display: none;
}

@media only screen and (max-width: 1000px) {
    
    .navbar{
        left: 20%;
        right: 20%;

        &__menu{
            scale: 0.9;
            gap: 20px;
            max-width: 300px;

            &__link{

                button{
                    //width: 160px;
                    
                    span{
                        font-size: 16pt;
                    }
                }
            }
        }
    }
}
