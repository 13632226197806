html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.App {


    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx0LQ6VkBf2mfuZ0iZmZHShhwoAmdi_bd3sA&s');
        background: url('https://images.rawpixel.com/image_social_landscape/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTAyL3JtNjE5LTIwLWMtcGxhaW4tdGEtMDAxYi5qcGc.jpg');
        background: url('https://www.patternpictures.com/wp-content/uploads/Dark-Grey-Couch-Fabric-Pattern-patternpictures-4413-1500x996.jpg');
        background: url('https://static.vecteezy.com/system/resources/thumbnails/006/688/262/small/triangle-geometric-black-3d-background-dark-mosaic-geometry-pattern-polygon-shape-pattern-backdrop-triangular-creative-template-abstract-modern-wallpaper-design-illustration-vector.jpg');
        background: url('https://png.pngtree.com/thumb_back/fw800/background/20231014/pngtree-wavy-fabric-background-textured-white-fabric-pattern-image_13618544.png');
        background: url('./assets/noise.png');
        mix-blend-mode: overlay;
        opacity: 0.6; 
        pointer-events: none; 
        z-index: -1; 
    }
}