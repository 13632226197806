.projects {
    padding-top: 200px;
    padding-left: 20%;
    padding-right: 20%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}
