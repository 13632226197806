.bottombar {
    position: absolute;
    bottom: 100px;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;

    &__menu {
        box-shadow: var(--shadow);
        border-width: 3px 3px 3px 3px;
        border-color: var(--white);
        border-style: solid;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 400px;
        flex: 1;
        backdrop-filter: blur(4px);
        border-radius: 100px;
        background-color: rgba(29, 17, 23, 0.8);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 60px;
        padding-right: 60px;
        justify-content: center;
        display: flex;
        list-style: none;
        text-align: center;
        gap: 40px;
        z-index: 10;

        &__link {

            button:hover{
                background-color: rgba(37, 27, 34, 0.3);
            }

            button {
                width: 180px;
                padding: 10px;
                background-color: rgba(105, 25, 83, 0);
                border: none;
                border-radius: 50px;
                position: relative;
                overflow: hidden;

                span {
                    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                    font-weight: 400;
                    font-size: 18pt;
                    color: var(--white);
                    position: relative;
                    z-index: 1;
                }


                ::after {
                    content: '';
                    position: absolute;
                    top: 25px;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 3px;
                    background-color: var(--white);
                    transition: width 0.3s ease;
                }

                &.navbar__menu__link__active::after,
                :hover::after {
                    width: 100%;
                }
            }

            .active{

                border: 3px solid var(--primary-color);
                background-color: rgba(27, 0, 19, 0.3);

                span {
                    font-weight: 600;
                    color: var(--primary-color);
                }

                ::after {
                    width: 100%;
                    background-color: var(--primary-color);
                }
            }
        }
    }
}

.nav-icon {
    display: none;
}

@media only screen and (max-width: 460px) {
    .bottombar{

        bottom: 50px;

        &__menu{
            scale: 0.7;
            gap: 10px;
            max-width: 300px;
            min-width: 260px;

            &__link{

                button{
                    width: 160px;
                    
                    span{
                        font-size: 16pt;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 461px) and (max-width: 800px) {
    .bottombar{

        bottom: 50px;

        &__menu{
            scale: 0.9;
            gap: 20px;
            max-width: 300px;

            &__link{

                button{
                    width: 160px;
                    
                    span{
                        font-size: 16pt;
                    }
                }
            }
        }
    }
}
