.md {
    padding-top: 200px;
    padding-bottom: 300px;
    width: 60%;
    max-width: 800px;
    margin: 0 auto;
    

    h1, h2, h3 {
        color: var(--primary-color);
    }

    p, li{
        color: var(--white);        
    }

    img{
        width: auto;
        height: auto;
        min-width: 500px;
        max-width: 800px;
        max-height: 700px;
    }
}
