.home {
    position: relative;
    overflow: hidden;
    height: 100vh; 
    width: 100%;

    &__deactive {
        transform: translateY(-200%);
    }

    &__main {
        width: 100%;
        height: 100%;
        z-index: 5;
        position: relative;
        transition: transform 1s;

        &__avatar {
            position: absolute;
            top: 20%; 
            left: 50%;
            transform: translateX(-50%);
            width: 1200px;
            height: auto;
            z-index: 5;
        }

        &__hands {
            position: absolute;
            width: 360px;
            height: auto;
            z-index: 6;
            transition: transform 0.4s;        
        }

        &__hands1 {
            bottom: 8%; 
            left: calc(50% + 250px); 

            &:hover {
                transform: translateX(-10%);
            }
        }

        &__hands2 {
            bottom: 5%; 
            left: calc(50% - 250px - 360px); 

            &:hover {
                transform: translateX(10%);
            }
        }

        &__hands3 {
            bottom: 40%; 
            left: calc(50% - 250px  - 360px);

            &:hover {
                transform: translateX(10%);
            }
        }
    }

    &__deactive-circles {
        transform: translateY(-40%);
    }

    &__circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: transform 2s;
        z-index: 1;

        &__base {
            position: absolute;
            display: block; 
            background: var(--radial-gradient);
            border-radius: 50%;
            box-shadow: var(--shadow);
            z-index: 1;
        }

        &__1 {
            top: 50%;
            left: calc(50% - 400px);
            transform: translate(-50%, -50%);
            height: 800px;
            width: 800px;
            box-shadow: 0 0px 30px var(--shadow-color);
        }

        &__2 {
            top: 18%; 
            left: 8%;
            transform: translate(-50%, -50%);
            height: 300px;
            width: 300px;
        }

        &__3 {
            top: 60%; 
            left: 20%;
            transform: translate(-50%, -50%);
            height: 250px;
            width: 250px;
        }

        &__4 {
            top: 50%; 
            right: 20%;
            transform: translate(50%, -50%); 
            height: 350px;
            width: 350px;
        }

        &__5 {
            top: 3%; 
            right: 8%;
            transform: translate(50%, -50%); 
            height: 230px;
            width: 230px;
        }

        &__6 {
            top: -7%;
            left: 30%;
            transform: translate(-50%, -50%);
            height: 200px;
            width: 200px;
            box-shadow: 0 0px 30px var(--shadow-color);
        }
    }

    &__active-form {
        pointer-events: all;
    }

    &__deactive-form {
        transform: translateY(70%);
    }

    &__contact {
        position: relative;
        width: 800px;
        height: 800px;
        top: 50%;
        left: calc(50% - 400px);
        transition: transform 2s;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .contact-form {
            padding-bottom: 100px;
        }
    }
}

@media only screen and (max-width: 749px){

    .home{
        height: 90vh;
        
        &__main {
    
            &__avatar {
                top: 22%;
                width: 500px;
            }

            &__hands {
                width: 120px;
            }

            &__hands1{
                left: calc(50% + 100px); 
                bottom: 40%; 
            }
            
            &__hands2{
                left: calc(50% - 100px - 120px); 
                bottom: 50%; 
            }

            &__hands3{
                left: calc(50% - 100px - 120px); 
                bottom: 32%; 
            }
        }

        &__circles {
            
            &__base{
                scale: 0.7;
            }

            &__1 {
                top: 50%;
                left: calc(50% - 400px);

            }
    
            &__2 {
                top: 18%; 
                left: 5%;
            }

            &__3 {
                top: 60%; 
                left: 5%;
            }
    
            &__4 {
                top: 50%; 
                right: 0%;
            }
    
            &__5 {
                top: 3%; 
                right: 6%;
            }
        }

        &__deactive-form {
            transform: translateY(120%);
        }

        &__contact {
            width: 95%;
            max-width: 600px;
            height: 600px;
            top: 60%;
            left: 0px;
            
            .contact-form {
                padding-bottom: 0px;

                p{
                    font-size: 12pt;
                }

                span{
                    font-size: 12pt;
                }
            }
        }
    }
}

@media only screen and (min-width : 750px) and (max-width: 999px){

    .home{
        height: 90vh;

        &__main {
    
            &__avatar {
                top: 22%;
                width: 900px;
            }

            &__hands {
                width: 220px;
            }

            &__hands1{
                left: calc(50% + 180px); 
                bottom: 18%; 
            }
            
            &__hands2{
                left: calc(50% - 180px - 220px); 
                bottom: 10%; 
            }

            &__hands3{
                left: calc(50% - 180px - 220px); 
                bottom: 40%; 
            }
        }

        &__circles {
            
            &__base{
                scale: 0.8;
            }

            &__1 {
                top: 50%;
                left: calc(50% - 400px);
                transform: translate(-50%, -50%);

            }
    
            &__2 {
                top: 18%; 
                left: 8%;
            }

            &__3 {
                top: 60%; 
                left: 10%;
            }
    
            &__4 {
                top: 50%; 
                right: 10%;
            }
    
            &__5 {
                top: 3%; 
                right: 6%;
            }
        }

        &__deactive-form {
            transform: translateY(100%);
        }

        &__contact {
            width: 600px;
            height: 600px;
            top: 60%;
            left: calc(50% - 300px);
            
            .contact-form {
                padding-bottom: 0px;
            }
        }
    }
}

@media only screen and (min-width : 1000px) and (max-width: 1301px){

    .home{

        &__main {
    
            &__avatar {
                top: 22%;
                width: 1000px;
            }

            &__hands {
                width: 260px;
            }

            &__hands1{
                left: calc(50% + 220px); 
                bottom: 18%; 
            }
            
            &__hands2{
                left: calc(50% - 220px - 260px); 
                bottom: 10%; 
            }

            &__hands3{
                left: calc(50% - 220px - 260px); 
                bottom: 40%; 
            }
        }

        &__deactive-form {
            transform: translateY(90%);
        }

        &__circles {
            
            &__base{
                scale: 0.9;
            }
        }
    }
}