.project-card {
    width: 300px;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    position: relative;
    transition: transform 0.2s;

    &__image-container {
        position: relative;
        width: 300px;
        height: 200px;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: blur(0px);
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &__title {
        font-size: 18px;
        margin: 10px 0;
        color: #fff; 
    }

    &__description {
        font-size: 14px;
        color: #fff; 
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:hover &__overlay {
        opacity: 1;
    }
    &:hover &__image{
        filter: blur(1px);
    }
}
